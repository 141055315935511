import { BgIcon, Button, CMSLayout, Col, Grid, Row, Table, Text, useMobileScreen } from 'components';
import React, { useEffect, useState, useCallback, useRef } from 'react';
import { IScreen } from 'type';
import { Tooltip as TooltipComponent } from 'react-tippy';
import { ColumnsType } from 'antd/es/table';
import apiClient from 'store/api-client';
import { ValHelper } from 'helpers';
import { DatePicker, Input, notification, Pagination } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { COLORS } from 'const';
import { debounce } from 'lodash';
import FinanceExportModal from './components/FinanceExportModal';

const { RangePicker } = DatePicker;

const PAGE_SIZE = 20;
const Finance: IScreen = () => {
  const isMobile = useMobileScreen();
  const [resellers, setResellers] = useState([]);
  const [page, setPage] = useState(1);
  const [searchKeyword, setSearchKeyword] = useState('');
  const searchKeywordRef = useRef(searchKeyword);
  const [filterTimeRange, setFilterTimeRange] = useState<Dayjs[]>([dayjs().startOf("year"), dayjs().endOf("year")]);
  const [total, setTotal] = useState(0);
  const [summary, setSummary] = useState({
    salesTotal: 0,
    shippingTotal: 0,
    vatTotal: 0,
    sampleSalesTotal: 0,
  });
  const sorter = useRef<{ [key: string]: any }>();
  const filter = useRef<{ [key: string]: any }>();
  const [isLoading, setIsLoading] = useState(false);
  const exportModalRef = useRef<any>();

  const getData = useCallback(async (p = 1) => {
    setIsLoading(true);
    const params: any = {
      page: p,
      pageSize: PAGE_SIZE,
      search: searchKeywordRef.current,
    }
    if (sorter.current?.order) {
      params.sortBy = sorter.current.columnKey
      params.sortOrder = sorter.current.order === "descend" ? "DESC" : "ASC"
    }
    try {
      const res = await apiClient.Api.User.listResellers(params);
      setResellers(res?.data?.data?.list || []);
      setTotal(res?.data?.data?.total || 0);
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error?.message || 'Something went wrong',
      })
    } finally {
      setIsLoading(false);
    }
  }, []);

  const debouncedGetData = useCallback(
    debounce(getData, 300),
    []
  );

  useEffect(() => {
    apiClient.Api.User.resellerSalesReport({}).then((res) => {
      setSummary(res?.data?.data?.totals || {});
    })
  }, [])

  useEffect(() => {
    debouncedGetData(page);
  }, [page]);

  const onChangeFilter = useCallback((_, _filter, _sorter) => {
    sorter.current = _sorter;
    filter.current = _filter;
    setPage(1);
    debouncedGetData(1);
  }, []);

  const columns: ColumnsType<any> = [
    {
      title: 'Reseller',
      key: 'reseller',
      render: (text, record) => {
        return (
          <Col>
            <Text mb0>
              {record.firstName} {record.lastName}
            </Text>
            <Text caption>{record.email}</Text>
          </Col>
        )
      }
    },
    {
      title: 'Balance',
      key: 'walletBalance',
      render: (text, record) => {
        return <Text>£ {ValHelper.formatMoney(record.walletBalance)}</Text>
      },
      sorter: true
    },
    {
      title: 'Sales Total',
      key: 'salesTotal',
      render: (text, record) => {
        return <Text>£ {ValHelper.formatMoney(record.salesTotal / 100)}</Text>
      },
      sorter: true
    },
    {
      title: 'Sample Total',
      key: 'sampleSalesTotal',
      render: (text, record) => {
        return <Text>£ {ValHelper.formatMoney(record.sampleSalesTotal / 100)}</Text>
      },
      sorter: true
    },
    {
      title: 'Wholesale Total',
      key: 'wholesaleTotal',
      render: (text, record) => {
        return <Text>£ {ValHelper.formatMoney(record.wholesaleTotal / 100)}</Text>
      },
      sorter: true
    },
    {
      title: 'VAT Total',
      key: 'vatTotal',
      render: (text, record) => {
        return <Text>£ {ValHelper.formatMoney(record.vatTotal / 100)}</Text>
      },
      sorter: true
    },
    {
      title: 'Shipping Total',
      key: 'shippingTotal',
      render: (text, record) => {
        return <Text>£ {ValHelper.formatMoney(record.shippingTotal / 100)}</Text>
      },
      sorter: true
    }
  ].filter(Boolean);

  return (
    <CMSLayout
      requireAuthen
      breadcrumbs={[
        { title: 'Finance' },
      ]}
    >
      <Col round1
        {...isMobile ? {
          marginHorizontal: 8,
          marginTop: 10,
        } : {
          m2: true,
          bgWhite: true,
          mt1: true,
          p2: true,
          flex1: true,
        }}
      >
        <Col>
          <Row alignItems={'flex-start'}>
            <Col flex1>
              <TooltipComponent
                title={'Total Sales (excluding VAT & Shipping)'}
                position="top"
              >
                <Col shadow round0 bgWhite flex1 height={120} middle mr1 mb1
                >
                  <Text textAlign={"center"}>Total Sales</Text>
                  <Text fontSize={30}>£ {ValHelper.formatMoney((summary.salesTotal + summary.sampleSalesTotal) / 100)}</Text>
                </Col>
              </TooltipComponent>
            </Col>
            <Col flex1>
              <TooltipComponent
                title={'Total Shipping'}
                position="top"
              >
                <Col shadow round0 bgWhite flex1 height={120} middle mr1 mb1>
                  <Text textAlign={"center"}>Total Shipping</Text>
                  <Text fontSize={30}>£ {ValHelper.formatMoney(summary.shippingTotal / 100)}</Text>
                </Col>
              </TooltipComponent>
            </Col>
            <Col flex1>
              <TooltipComponent
                title={'Total VAT'}
                position="top"
              >
                <Col shadow round0 bgWhite flex1 height={120} middle mb1>
                  <Text textAlign={"center"}>Total VAT</Text>
                  <Text fontSize={30}>£ {ValHelper.formatMoney(summary.vatTotal / 100)}</Text>
                </Col>
              </TooltipComponent>
            </Col>
          </Row>
        </Col>
        <Col alignItems={'flex-end'}>
          <Button
            text="Export report"
            height={40}
            width={180}
            paddingHorizontal={16}
            borderRadius={20}
            onPress={async () => {
              exportModalRef.current?.show();
            }}
          />
        </Col>
        <Row mt2 mb1>
          {/* <RangePicker
            onChange={setFilterTimeRange}
            // @ts-ignore
            value={filterTimeRange}
            size="large"
          /> */}
          <Input
            value={searchKeyword}
            size="large"
            placeholder="Search by name, email"
            prefix={(
              <Col marginBottom={-4}>
                <BgIcon name="search" size={18} color={COLORS.ICON_MUTED} />
              </Col>
            )}
            onChange={(e) => {
              setSearchKeyword(e.target.value)
              searchKeywordRef.current = e.target.value;
            }}
            onPressEnter={() => {
              setPage(1);
              debouncedGetData(1);
            }}
          />
        </Row>
        <Table
          data={resellers}
          columns={columns}
          rowKey={val => `${val.id}`}
          onChange={onChangeFilter}
          loading={isLoading && page === 1}
        />
        <Pagination
          style={{ marginTop: 12 }}
          pageSize={PAGE_SIZE}
          current={page}
          total={total}
          onChange={setPage}
          showSizeChanger={false}
        />
      </Col>
      <FinanceExportModal ref={exportModalRef} />
    </CMSLayout>
  )
};

Finance.routeInfo = {
  title: 'Finance - Bottled Goose',
  path: '/finance',
};

export default Finance;
