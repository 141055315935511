import _ from 'lodash';
import { TPackingSlip } from 'store/api-client/type/TPackingSlip';
import { TCandleStickerInfo, TOnlineStore, TProduct, TUser } from 'type';

class ValHelper {
  isDev = typeof window === undefined ? true : window.location.hostname.includes('dev.')|| process.env.NODE_ENV === 'development'
  isDevQuery = typeof window === undefined ? false : window.location.href.includes('dev=1')
  editorHost = !this.isDev ? 'https://bg-personify-editor.vercel.app' : 'https://bottled-goose-huyden181-codesigned.vercel.app';

  jsUCFirst = (string) => string.charAt(0).toUpperCase() + string.slice(1);

  formatBalance = (balance: number) => (Math.round(balance * 100) / 100).toFixed(2);

  isKeywordInFields = (values: string[], keyword: string) => {
    if (!values?.length) return false;
    if (!keyword) return true;
    const txt = values.map(i => String(i).toLowerCase()).join('|');
    return txt.includes(keyword.toLowerCase());
  }

  parseClientId = (id) => {
    if (Number(id)) return id;
    if (id === 'partnet-in-wine' || id === 'piw') return '665847158329';
    if (id === 'ghg') return '578953627077';
    return '440478626329';
  }

  validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  formatMoney = (amount: string | number) => {
    const _amount = Number(amount || 0);
    if (Number.isNaN(_amount)) return amount || "0.00";
    return _amount.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  };

  objectToQueryString = (obj) => {
    return _.map(obj, (value, key) => {
      return encodeURIComponent(key) + '=' + encodeURIComponent(value);
    }).join('&');
  };

  toDisplayStage = (s?: string) => {
    if (!s || typeof s !== "string") return undefined;
    return s.split("-").map(_.capitalize).join(" ");
  };

  noSingleLastWordHTML = (value: string) => {
    if (typeof value !== 'string') return '';
    if (!value.trim().includes(" ")) return value;
    const parts = value.trim().split(" ");
    const length = parts.length;
    parts[length - 2] = parts[length - 2] + `\u00A0` + parts[length - 1];
    parts[length - 1] = '';
    return parts.join(" ");
  }

  calculateDPI = (widthMM: number, heightMM: number): number => {
    const minDPI = 300;
    const inchToMM = 25.4;

    let dpi = minDPI;
    while (true) {
      const pixelPerMM = dpi / inchToMM;
      const calculatedWidth = widthMM * pixelPerMM;
      const calculatedHeight = heightMM * pixelPerMM;
      const roundWidth = Math.round(calculatedWidth);
      const roundHeight = Math.round(calculatedHeight);

      const convertBackWidth = (roundWidth / pixelPerMM).toFixed(2);
      const convertBackHeight = (roundHeight / pixelPerMM).toFixed(2);

      if (convertBackWidth === widthMM.toFixed(2) && convertBackHeight === heightMM.toFixed(2)) {
        return dpi;
      }

      dpi += 1;
      if (dpi >= 500) return minDPI;
    }
  }

  convertTypeToTitle = (input: string) => {
    return input
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  calculateDiscountedPrice = (
    data: {
      price: number,
      quantity: number,
      discountType: string,
    },
    packPrices: {
      size: string | number,
      discount?: string | number,
      price?: string | number,
    }[]
  ) => {
    const { price, quantity, discountType } = data;
    if (!discountType) return Number(price || 0);

    const sortedPackPrices = packPrices.sort((a, b) => Number(b.size) - Number(a.size));
    const packPrice = sortedPackPrices.find((packPrice) => Number(quantity) >= Number(packPrice.size));

    if (!packPrice) return Number(price || 0);
    if (discountType === 'price') {
      return Number(packPrice.price || price || 0);
    }
    return Math.round((price * (1 - Number(packPrice.discount) / 100)) * 100) / 100;
  }

  getDefaultCandleStickerInfo = (params: {
    candleInfo: TCandleStickerInfo,
    product: TProduct,
    user: TUser,
    packingSlip: TPackingSlip,
  }): TCandleStickerInfo => {
    const { candleInfo, product, user, packingSlip } = params || {};
    const { scentName, defaultScentName, companyName, logo, address, country, address2, state, postCode } = candleInfo || {};

    const defaultName = product?.data?.candleTemplate?.includes('black') ? 'Amber Oud' : (
      product?.data?.candleTemplate?.includes('white') ? `Surf's Up` : 'English Garden'
    )

    const removeDuplicateAddress = () => {
      let fullAddress = packingSlip?.address || user?.addressLine1
      if (!fullAddress) return "";
      if (user?.country && fullAddress.includes(user?.country)) {
        fullAddress = fullAddress.replace(user?.country, '');
      }
      if (user?.town && fullAddress.includes(user?.town)) {
        fullAddress = fullAddress.replace(user?.town, '');
      }
      if (user?.postCode && fullAddress.includes(user?.postCode)) {
        fullAddress = fullAddress.replace(user?.postCode, '');
      }
      return fullAddress.split(',').filter(i => !!i.trim()).join(', ');
    }

    return {
      scentName: scentName,
      defaultScentName: defaultScentName || defaultName,
      companyName: companyName || packingSlip?.companyName || [user?.firstName, user?.lastName].filter(Boolean).join(' '),
      logo: logo || packingSlip?.companyLogo,
      address: address || removeDuplicateAddress(),
      country: country || (address ? '' : user?.country),
      address2: address2 || (address ? '' : user?.addressLine2),
      state: state || (address ? '' : user?.town),
      postCode: postCode || (address ? '' : user?.postCode),
    }
  }

  formatOriginalPrice = (originalPrice: number) => {
    const symbols = {
      '1': '1̶',
      '2': '2̶',
      '3': '3̶',
      '4': '4̶',
      '5': '5̶',
      '6': '6̶',
      '7': '7̶',
      '8': '8̶',
      '9': '9̶',
      '.': '.̶',
      ',': ',̶',
    }
    return String(this.formatMoney(originalPrice))?.split('').map(i => symbols[i] || i).join('');
  }
}

export default new ValHelper();
