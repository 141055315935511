import Request from '../Request.utils'
export interface ILoginRequestBody {
  email: string;
  password: string;
}
export interface ILoginAsResellerRequestBody {
  email: string;
  passwordHash: string;
}
export interface IRegisterRequestBody {
  __key?: string;
  accountName?: string;
  addressLine1?: string;
  addressLine2?: string;
  country?: string;
  email: string;
  firstName: string;
  lastName?: string;
  otherData?: any;
  password: string;
  photoUrl?: string;
  postCode?: string;
  role: string;
  town?: string;
}
export interface IGetUploadPresignedUrlRequestBody {
  contentType: string;
  serverSidePath: string;
}
export interface ISocialAuthRequestBody {
  idToken: string;
  provider: 'google' | 'facebook';
}
export interface IListResellersRequestQuery {
  countDesign?: boolean;
  countStore?: boolean;
  hasStore?: boolean;
  page?: number;
  pageSize?: number;
  search?: string;
  sortBy?: string;
  sortOrder?: string;
}
export interface IDetailRequestParams {
  id: string;
}
export interface IUpsertResellerRequestBody {
  accountName?: string;
  addressLine1?: string;
  addressLine2?: string;
  country?: string;
  currency?: string;
  email?: string;
  firstName?: string;
  id?: string;
  isAutoAccept?: boolean;
  lastName?: string;
  otherData?: any;
  password?: string;
  photoUrl?: string;
  postCode?: string;
  town?: string;
}
export interface IUpsertResellerUserRequestBody {
  addressLine1?: string;
  addressLine2?: string;
  country?: string;
  email?: string;
  firstName?: string;
  id?: string;
  lastName?: string;
  onlineStoreId?: string;
  otherData?: any;
  password?: string;
  photoUrl?: string;
  postCode?: string;
  town?: string;
}
export interface IListResellerUserRequestQuery {
  page?: number;
}
export interface IDetailResellerUserRequestParams {
  id: string;
}
export interface IUpdateProfileRequestBody {
  accountName?: string;
  firstName?: string;
  isAutoAccept?: '';
  lastName?: string;
  password?: string;
}
export interface ICheckAutoAcceptOrderRequestParams {
  id: string;
}
export interface IDeleteResellerRequestParams {
  id: string;
}
export interface IInviteUserRequestBody {
  emails?: string[];
}
export interface ICheckInviteCodeRequestBody {
  code: string;
  email: string;
  firstName?: string;
  lastName?: string;
  password: string;
}
export interface ICheckImageQualityRequestBody {
  callbackUrl?: string;
  urls?: string[];
}
export interface IForgotPasswordRequestBody {
  email: string;
}
export interface IResetPasswordRequestBody {
  code: string;
  newPassword: string;
}
export interface IVerifyForgotPasswordRequestBody {
  code: string;
}
export interface IGetResellerWalletBalanceRequestParams {
  resellerId: string;
}
export interface IGetUserEditorImagesRequestParams {
  userId: string;
}
export interface ISetUserEditorImagesRequestBody {
  images: string[];
}
export interface ISetUserEditorImagesRequestParams {
  userId: string;
}
export interface IResellerSalesReportRequestQuery {
  fromDate?: string;
  toDate?: string;
}


class UserAPI {
  login = async (body: ILoginRequestBody) => {
    const res = await Request.call('/api/users/login', 'POST', undefined, undefined, body, );
    if (res.data.data && res.data.data.token) Request.setToken(res.data.data.token)
    return res;
  }
  loginAsReseller = async (body: ILoginAsResellerRequestBody) => {
    const res = await Request.call('/api/users/admin-login-as-reseller', 'POST', undefined, undefined, body, );
    return res;
  }
  logout = async () => {
    const res = await Request.call('/api/users/logout', 'POST', undefined, undefined, undefined, );
    Request.setToken('')
    return res;
  }
  register = async (body: IRegisterRequestBody) => {
    const res = await Request.call('/api/users/register', 'POST', undefined, undefined, body, );
    return res;
  }
  me = async () => {
    const res = await Request.call('/api/users/me', 'GET', undefined, undefined, undefined, );
    return res;
  }
  getUploadPresignedUrl = async (body: IGetUploadPresignedUrlRequestBody) => {
    const res = await Request.call('/api/users/me/get-upload-url', 'POST', undefined, undefined, body, );
    return res;
  }
  socialAuth = async (body: ISocialAuthRequestBody) => {
    const res = await Request.call('/api/users/social-auth', 'POST', undefined, undefined, body, );
    return res;
  }
  listResellers = async (query: IListResellersRequestQuery) => {
    const res = await Request.call('/api/users/resellers', 'GET', undefined, query, undefined, );
    return res;
  }
  detail = async (params: IDetailRequestParams) => {
    const res = await Request.call('/api/users/:id', 'GET', params, undefined, undefined, );
    return res;
  }
  upsertReseller = async (body: IUpsertResellerRequestBody) => {
    const res = await Request.call('/api/users/resellers', 'POST', undefined, undefined, body, );
    return res;
  }
  upsertResellerUser = async (body: IUpsertResellerUserRequestBody) => {
    const res = await Request.call('/api/users/resellers-user', 'POST', undefined, undefined, body, );
    return res;
  }
  listResellerUser = async (query: IListResellerUserRequestQuery) => {
    const res = await Request.call('/api/users/resellers-user', 'GET', undefined, query, undefined, );
    return res;
  }
  detailResellerUser = async (params: IDetailResellerUserRequestParams) => {
    const res = await Request.call('/api/users-reseller/:id', 'GET', params, undefined, undefined, );
    return res;
  }
  updateProfile = async (body: IUpdateProfileRequestBody) => {
    const res = await Request.call('/api/users/me/profile', 'POST', undefined, undefined, body, );
    return res;
  }
  checkAutoAcceptOrder = async (params: ICheckAutoAcceptOrderRequestParams) => {
    const res = await Request.call('/api/reseller/:id/check-auto-accept', 'GET', params, undefined, undefined, );
    return res;
  }
  deleteReseller = async (params: IDeleteResellerRequestParams) => {
    const res = await Request.call('/api/users/:id', 'DELETE', params, undefined, undefined, );
    return res;
  }
  inviteUser = async (body: IInviteUserRequestBody) => {
    const res = await Request.call('/api/users/invite', 'POST', undefined, undefined, body, );
    return res;
  }
  checkInviteCode = async (body: ICheckInviteCodeRequestBody) => {
    const res = await Request.call('/api/users/check-invite-code', 'POST', undefined, undefined, body, );
    return res;
  }
  checkImageQuality = async (body: ICheckImageQualityRequestBody) => {
    const res = await Request.call('/api/users/me/check-image-quality', 'POST', undefined, undefined, body, );
    return res;
  }
  forgotPassword = async (body: IForgotPasswordRequestBody) => {
    const res = await Request.call('/api/users/forgot-password', 'POST', undefined, undefined, body, );
    return res;
  }
  resetPassword = async (body: IResetPasswordRequestBody) => {
    const res = await Request.call('/api/users/reset-password', 'POST', undefined, undefined, body, );
    return res;
  }
  verifyForgotPassword = async (body: IVerifyForgotPasswordRequestBody) => {
    const res = await Request.call('/api/users/verify-forgot-password', 'POST', undefined, undefined, body, );
    return res;
  }
  getResellerSampleToken = async () => {
    const res = await Request.call('/api/users/reseller/sample-token', 'GET', undefined, undefined, undefined, );
    return res;
  }
  getResellerWalletBalance = async (params: IGetResellerWalletBalanceRequestParams) => {
    const res = await Request.call('/api/users/:resellerId/wallet-balance', 'GET', params, undefined, undefined, );
    return res;
  }
  getUserEditorImages = async (params: IGetUserEditorImagesRequestParams) => {
    const res = await Request.call('/api/users/:userId/editor-images', 'GET', params, undefined, undefined, );
    return res;
  }
  setUserEditorImages = async (params: ISetUserEditorImagesRequestParams, body: ISetUserEditorImagesRequestBody) => {
    const res = await Request.call('/api/users/:userId/editor-images', 'POST', params, undefined, body, );
    return res;
  }
  isResellerCompleteSetup = async () => {
    const res = await Request.call('/api/users/is-reseller-complete-setup', 'GET', undefined, undefined, undefined, );
    return res;
  }
  sendEmailWithDesktopLink = async () => {
    const res = await Request.call('/api/users/send-email-with-desktop-link', 'POST', undefined, undefined, undefined, );
    return res;
  }
  resellerSalesReport = async (query: IResellerSalesReportRequestQuery) => {
    const res = await Request.call('/api/users/resellers-sales-report', 'GET', undefined, query, undefined, );
    return res;
  }
}
export default new UserAPI()