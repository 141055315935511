import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { Modal } from 'antd';
import { Col, showPopupMessage } from 'components';
import apiClient from 'store/api-client';
import { saveAs } from 'file-saver';
import { TimeHelper, ValHelper } from 'helpers';
import { ActivityIndicator } from 'react-native';
import { COLORS } from 'const';
import moment from 'moment';

let XLSX;

const downloadExcel = (data, title) => {
  var wb = XLSX.utils.book_new();
  const formatedData = [];
  data.forEach((v: any) => {
    formatedData.push({
      'Reseller ID': v.id,
      'Name': `${v.firstName} ${v.lastName}`,
      'Email': v.email,
      'Account Name': v.accountName,
      'Wallet Balance': `£${ValHelper.formatMoney(v.walletBalance)}`,
      'Created At': TimeHelper.formatDay(v.createdAt),
      'Last Logged In': TimeHelper.formatDayTime(v.loggedInAt),
      'Number of Designs': String(v.designCount || 0).trim(),
      'Store Connected': v.hasStore ? 'Yes' : 'No',
    })
  });
  XLSX.utils.book_append_sheet(wb, XLSX.utils.json_to_sheet(formatedData), "Orders");

  var wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  saveAs(new Blob([wbout], { type: "application/octet-stream" }), `${title}.xlsx`);
};

const ResellerExportModal = (props: any, ref) => {
  const [show, setShow] = useState(false);
  const pageRef = useRef(1);

  const getPageData = async () => {
    try {
      const res = await apiClient.Api.User.listResellers({
        page: pageRef.current,
        pageSize: 1000,
        countDesign: true,
        countStore: true,
      })
      return res.data?.data?.list || [];
    } catch (err) {
      return [];
    }
  }
  const onSubmit = async () => {
    try {
      setShow(true);
      let data = [];
      let hasMore = true;
      pageRef.current = 1;
      
      while (hasMore) {
        const pageData = await getPageData();
        data = [...data, ...pageData];
        
        if (pageData.length < 1000) {
          hasMore = false;
        } else {
          pageRef.current += 1;
        }
      }

      if (data.length) {
        downloadExcel(data, `reseller-export_${moment().format('DD-MM-YYYY')}`);
      }
    } catch (err) {
      showPopupMessage({
        title: '',
        content: String(err),
        buttonOkText: 'OK',
        typeHighlight: 'danger',
        contentHighlight: 'Error'
      });
    } finally {
      setShow(false);
    }
  }

  useImperativeHandle(ref, () => ({
    export: () => {
      if (!XLSX) {
        import('xlsx').then(xlsx => {
          XLSX = xlsx;
        });
      }
      onSubmit();
    },
  }));

  return (
    <Modal
      open={show}
      title={"Export reseller"}
      onOk={onSubmit}
      onCancel={() => setShow(false)}
      footer={false}
    >
      <Col>
        <ActivityIndicator color={COLORS.BLUE} />
      </Col>
    </Modal>
  );
};

export default forwardRef(ResellerExportModal);
