import React, { useEffect, useState } from 'react';
import {
  Col,
  Text,
  Row,
} from "components";
import { COLOR } from "const";
import Store from 'store';
import { Spin } from 'antd';

const SalesReport = ({ type }: { type: string }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { getSalesReport } = Store.useUserStore();
  const [data, setData] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const res = await getSalesReport(10, type);
        setData(res);
      } catch (error) {
        console.log("error", error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [type])

  if (isLoading) {
    return (
      <Col p2>
        <Spin />
      </Col>
    )
  }
  return (
    <>
      <Row p0 m0 mt2>
        <Col flex1>
          <Text bold>Name</Text>
        </Col>
        <Col width={50}>
          <Text bold>Total sales</Text>
        </Col>
      </Row>
      {data.map((v, vI) => {
        return (
          <Row
            p0
            m0
            key={vI}
            borderTopWidth={1}
            borderTopColor={COLOR.GREY_LIGHT}
            backgroundColor={
              vI % 2 === 0 ? COLOR.GREY_LIGHT : "transparent"
            }
          >
            <Row flex1>
              <img src={v.image} alt={v.name} style={{ width: 50, height: 50 }} />
              <Text bold ml1>{v.name}</Text>
            </Row>
            <Col width={50}>
              <Text>{v.quantity}</Text>
            </Col>
          </Row>
        );
      })}
    </>
  )
};

export default SalesReport;
