import { Col, CMSLayout } from 'components';
import React, { useState, useEffect, useCallback } from 'react';
import { IScreen } from 'type';
import Store, { Api } from 'store';
import { useNavFunc } from 'navigation';
import { Collapse, Typography, Spin } from 'antd';

const { Panel } = Collapse;
const { Title } = Typography;

type FAQItem = {
  question: string;
  answer: string;
}

const FAQs: IScreen = () => {
  const { navigation } = useNavFunc();
  const UserStore = Store.useUserStore();
  const { user } = UserStore;
  const userId = user?.id;
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<FAQItem[]>([]);

  const getData = useCallback(async () => {
    try {
      if (isLoading) return;
      setIsLoading(true);
      const res = await Api.Settings.getFAQs();
      if (res.data?.data?.data) {
        setData(JSON.parse(res.data?.data?.data));
      }
    } catch (error) {
      alert(error?.message || JSON.stringify(error))
    } finally {
      setIsLoading(false);
    }
  }, [isLoading]);

  useEffect(() => {
    if (!!userId) {
      getData()
      const unsubscribe = navigation.addListener('focus', () => {
        getData()
      });
      return unsubscribe;
    }
  }, [userId]);

  const styles = {
    title: {
      fontSize: 26,
      fontWeight: 'bold',
      letterSpacing: '0.05em',
    },
    collapse: {
      background: 'transparent',
      border: 'none',
    },
    panel: {
      marginBottom: 8,
      border: 'none',
      borderBottom: '1px solid #e8e8e8',
    },
    panelHeader: {
      fontSize: 16,
      fontWeight: 500,
      padding: '16px 0',
    },
    answer: {
      fontSize: 14,
      color: '#666',
      paddingBottom: 16,
    }
  };

  return (
    <CMSLayout requireAuthen>
      <Col flex1 bgWhite m2 borderRadius={10}>
        <Col alignItems="center" marginTop={24} marginBottom={12}>
          <Title style={styles.title}>
            Frequently Asked Questions
          </Title>
        </Col>

        <Col paddingHorizontal={32}>
          <Spin spinning={isLoading}>
            <Collapse
              ghost
              expandIconPosition="end"
              style={styles.collapse}
            >
              {data.map((item, index) => (
                <Panel
                  header={item.question}
                  key={index}
                  style={styles.panel}
                  className="faq-panel"
                >
                  <div style={styles.answer}>
                    {item.answer}
                  </div>
                </Panel>
              ))}
            </Collapse>
          </Spin>
        </Col>
      </Col>
    </CMSLayout>
  )
};

FAQs.routeInfo = {
  title: 'Bottled Goose - FAQs',
  path: '/faqs',
};

export default FAQs;
