import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Modal, DatePicker } from 'antd';
import { Button, Col, showPopupMessage, Text } from 'components';
import dayjs, { Dayjs } from 'dayjs';
import apiClient from 'store/api-client';
import { saveAs } from 'file-saver';
import { ValHelper } from 'helpers';

let XLSX;

const { RangePicker } = DatePicker;

const lastMonth = dayjs().subtract(1, 'month');

const downloadExcel = (data, title) => {
  var wb = XLSX.utils.book_new();
  const formatedData = [];
  data.forEach((v: any) => {
    formatedData.push({
      'Reseller ID': v.id,
      'Name': v.name,
      'Email': v.email,
      'Account Name': v.accountName,
      'Sales Total': `£${ValHelper.formatMoney(v.salesTotal / 100)}`,
      'Sample Sales Total': `£${ValHelper.formatMoney(v.sampleSalesTotal / 100)}`,
      'Shipping Total': `£${ValHelper.formatMoney(v.shippingTotal / 100)}`,
      'VAT Total': `£${ValHelper.formatMoney(v.vatTotal / 100)}`,
    })
  });
  XLSX.utils.book_append_sheet(wb, XLSX.utils.json_to_sheet(formatedData), "Orders");

  var wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  saveAs(new Blob([wbout], { type: "application/octet-stream" }), `${title}.xlsx`);
};

const FinanceExportModal = (props: any, ref) => {
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [filterTimeRange, setFilterTimeRange] = useState<Dayjs[]>([lastMonth.startOf("month"), lastMonth.endOf("month")]);

  useImperativeHandle(ref, () => ({
    show: () => {
      if (!XLSX) {
        import('xlsx').then(xlsx => {
          XLSX = xlsx;
        });
      }
      setShow(true);
    },
  }));

  const onSubmit = async () => {
    setIsLoading(true);
    try {
      let fromDate = filterTimeRange[0].format('DD-MM-YYYY');
      let toDate = filterTimeRange[1].format('DD-MM-YYYY');
      const res = await apiClient.Api.User.resellerSalesReport({
        fromDate,
        toDate,
      })
      if (res.data?.data?.resellers?.length) {
        downloadExcel(res.data?.data?.resellers, `export-finance_${fromDate}-${toDate}`);
      }
    } catch (err) {
      showPopupMessage({
        title: '',
        content: String(err),
        buttonOkText: 'OK',
        typeHighlight: 'danger',
        contentHighlight: 'Error'

      });
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Modal
      open={show}
      title={"Export finance report"}
      onOk={onSubmit}
      onCancel={() => setShow(false)}
      footer={false}
    >
      <Col paddingTop={24}>
        <Text mb1>Select time range</Text>
        <RangePicker
          onChange={setFilterTimeRange}
          // @ts-ignore
          value={filterTimeRange}
          size="large"
        />
        <Col middle mt2>
          <Button
            text="Export"
            height={40}
            width={200}
            borderRadius={20}
            onPress={onSubmit}
            isLoading={isLoading}
            disabled={isLoading}
          />
        </Col>
      </Col>
    </Modal>
  );
};

export default forwardRef(FinanceExportModal);
