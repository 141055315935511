import React, { useMemo, useRef } from 'react';
import { Col, Text, Row, RatioCol, Grid, ImageField, showPopupMessage, Button } from 'components';
import {
  Image,
} from 'react-native';
import { AntDesign, MaterialIcons, FontAwesome5 } from '@expo/vector-icons';
import { COLOR } from 'const';
import Store from 'store';
import UploadBlendFile, {
  readBlendDataFromProduct,
} from "./UpsertProduct.UploadBlendFile";
import RequestUtils from 'store/api-client/Request.utils';
import { ValHelper } from 'helpers';

const ProductImages = ({ product, updateProduct, ...props }: any) => {

  const UserStore = Store.useUserStore();
  const blendData = useMemo(() => {
    return readBlendDataFromProduct(product);
  }, [product]);

  const swapGalaryImageWithMainImage = (galleryIndex) => {
    showPopupMessage({
      title: "",
      content: "Are you sure you want to swap this image with the main image?",
      buttonOkText: "OK",
      onButtonOkClick: async () => {
        updateProduct({
          image: product?.galleries[galleryIndex],
          galleries: product?.galleries.map((v, i) =>
            i === galleryIndex ? product?.image : v
          ),
        });
      },
      typeHighlight: "danger",
      contentHighlight: "This action is not reversible.",
    });
  };

  const galleriesRef = useRef(null);
  galleriesRef.current = (() => {
    const items = (product?.galleries || []).filter(v => Boolean(v));
    items.push(undefined);
    while (items.length < 4) {
      items.push(undefined);
    }
    return items;
  })();

  const outlineActionButtonProps = {
    outline: true,
    bgHovered: COLOR.GREY_BG,
    textStyle: { fontSize: 10 },
    height: 26,
    borderRadius: 13,
    width: 80,
    mh0: true,
  };

  return (
    <Col p2 {...props}>

      <Grid xs='100%' lg='any:1' alignItems={'flex-start'} mb2>
        <Col m1 width={200}>
          <Text h4 mb0>Main Image</Text>
          <Text caption mb0>Thumbnail in listing</Text>
          <Text caption>Dimensions: Square</Text>
        </Col>
        <Col m1>
          <ImageField
            ratio={1}
            width='75%'
            alignSelf='flex-end'
            value={product?.image}
            onChange={(value: string) => {
              updateProduct({ image: value })
            }}
          />
        </Col>
      </Grid>

      <Grid xs='100%' lg='any:1' alignItems={'flex-start'} mb2>
        <Col m1 width={200}>
          <Text h4 mb0>Banner Image</Text>
          <Text caption mb0>Banner carousel</Text>
          <Text caption>Dimensions: 1156 x 232</Text>
        </Col>
        <Col m1>
          <ImageField
            ratio={1156 / 232}
            width='75%'
            alignSelf='flex-end'
            value={product?.banner}
            onChange={(value: string) => updateProduct({ banner: value })}
          />
        </Col>
      </Grid>

      <Grid xs='100%' lg='any:1' alignItems={'flex-start'} mb2>
        <Col m1 width={200}>
          <Text h4 mb0>Image Galleries</Text>
          <Text caption>Dimensions: Square</Text>
        </Col>
        <Row m1 flexWrap={'wrap'} justifyContent={'flex-end'}>
          {galleriesRef.current.map((val, i) => (
            <Col width={120} height={150} m0 key={"gallery-" + i}>
              <ImageField
                ratio={1}
                width='100%'
                value={val}
                shortText
                onChange={(value: string) => {
                  const newGalleries = galleriesRef.current.slice();
                  newGalleries[i] = value;
                  updateProduct({ galleries: newGalleries })
                }}
              />
              <Col
                alignSelf={'center'}
                onPress={() => swapGalaryImageWithMainImage(i)}
                mt0
              >
                <Row middle>
                  <MaterialIcons
                    name="published-with-changes"
                    size={16}
                    color="rgba(0,0,0,0.5)"
                  />
                  <Text ml0 caption color="rgba(0,0,0,0.5)">
                    Swap
                  </Text>
                </Row>
              </Col>
            </Col>
          ))}
        </Row>
      </Grid>

      <Grid xs='100%' lg='any:1' alignItems={'flex-start'} mb2>
        <Col m1 width={200}>
          <Text h4 mb0>Blender files</Text>
          <Text caption>2d Preview</Text>
        </Col>
        <Row m1 flexWrap={'wrap'} justifyContent={'flex-end'}>
          {!!blendData ? (
            <Button
              text="Test Preview"
              iconLeft={
                <FontAwesome5 name={"eye"} size={10} color="black" />
              }
              {...outlineActionButtonProps}
              width={120}
              onPress={() => {
                window.open(
                  `${ValHelper.editorHost}/design/test?width=${product.editorWidth
                  }&height=${product.editorHeight}${blendData.blender
                    ? "&blend=" + blendData.blender.image
                    : ""
                  }${blendData.support
                    ? "&blend_support=" + blendData.support.image
                    : ""
                  }${product.dropletUrl
                    ? "&dropletUrl=" + product.dropletUrl
                    : ""
                  }&bgProductId=${product.id}&userId=${UserStore.user?.id
                  }${ValHelper.isDev ? "&dev=1" : ""}&dpi=${
                    product.dpi || ValHelper.calculateDPI(product.physicalWidth, product.physicalHeight)
                  }`
                );
              }}
            />
          ) : (
            <Col />
          )}
          <UploadBlendFile
            {...outlineActionButtonProps}
            product={product}
            setProduct={updateProduct}
          />
        </Row>
      </Grid>
    </Col>
  );
};

export default ProductImages;