import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  CMSLayout,
  Col,
  Row,
  Text,
  Button,
  useUIState,
  Grid,
  showPopupMessage,
  Select,
} from "components";
import { useNavFunc } from "navigation";
import { COLOR } from "const";
import { SettingsFormProvider } from "./FormContext";
import { useDynamicResponsiveValue } from "quickly-react";
import SurveySettings from "./Settings.Survey";
import CloneMeSettings from "./Settings.CloneMe";
import SurveyAnwserSettings from "./Settings.SurveyAnwser";
import FAQSettings from "./Settings.FAQ";

const TABS = [
  { id: "faq", label: "FAQ" },
  // { id: "survey-answer", label: "SurveyAnswers" },
  // { id: "survey", label: "Survey Form Editor" },
];

const SettingTabs = () => {
  const { navigation, route } = useNavFunc();
  const rV = useDynamicResponsiveValue(['xs', 'lg']);
  const isMobile = rV({ xs: true, lg: false });
  const { tabId }: any = route.params || {};
  const [currentTab, setCurrentTab] = useState<number>(0);
  const surveyRef = useRef<any>(null);
  const faqRef = useRef<any>(null);
  const [{ loading: submitting }, setSubmitUI] = useUIState();

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      loadData();
    });
    return unsubscribe;
  }, []);

  const loadData = () => {
    const tabIdx = tabId ? TABS.findIndex(tab => tab.id === tabId) : 0;
    if (tabId === 'survey' && surveyRef.current) {
      surveyRef.current.getData();
    }
    if (tabId === 'faq' && faqRef.current) {
      faqRef.current.getData();
    }
    setCurrentTab(tabIdx);
    onChangeTabs(tabIdx);
  }
  const submit = async () => {
    setSubmitUI({ loading: true });
    try {
      const res = await faqRef.current?.updateFAQ();
      if (!res) return;
      if (res.data.error) {
        showPopupMessage({
          title: '',
          content: String(res.data.error),
          buttonOkText: 'OK',

          typeHighlight: 'danger',
          contentHighlight: 'Error'

        });
      }
    } catch (err) {
      showPopupMessage({
        title: '',
        content: String(err),
        buttonOkText: 'OK',

        typeHighlight: 'danger',
        contentHighlight: 'Error'

      });
    }
    setSubmitUI({ loading: false });
  };

  const ButtonSave = useMemo(() => (
    <Button
      isLoading={submitting}
      text="Save"
      borderRadius={20}
      paddingVertical={6}
      paddingHorizontal={10}
      onPress={submit}
    />
  ), [submitting]);

  const checkChangedBeforeSwitch = () => new Promise((resolve) => {
    let currentType = TABS[currentTab]?.id;
    if (!currentType) return resolve(true);
    // if (checkIsChanged(currentType)) {
    //   modalConfirm({
    //     title: 'Warning',
    //     content: 'Are you sure you want to exit without saving?',
    //     onOk: () => {
    //       resolve(true);
    //       onSaved(currentType);
    //     },
    //     onCancel: () => {
    //       resolve(false);
    //     },
    //   })
    // } else {
    //   resolve(true);
    // }
  })

  const onChangeTabs = async (index: number) => {
    // const canChange = await checkChangedBeforeSwitch();
    // if (!canChange) return;

    setCurrentTab(index);
    const tabId = TABS[index]?.id;
    if (tabId) {
      navigation.setParams({ tabId });
    }
  };

  const tabOptions = TABS.map((item, index) => ({ label: item, value: index }));

  const content = useMemo(() => {
    switch (currentTab) {
      case 1:
        return <SurveyAnwserSettings />;
      case 2:
        return <SurveySettings ref={surveyRef} />;
      case 0:
      default:
        return <FAQSettings ref={faqRef} />;
    }
  }, [currentTab]);

  const button = useMemo(() => {
    switch (currentTab) {
      case 0:
        return ButtonSave;
      default:
        return null;
    }
  }, [currentTab, ButtonSave]);

  return (
    <CMSLayout requireAuthen>
      <Row m2 marginBottom={0} justifyContent={"space-between"}>
        <Row>
          <Text h3 marginRight={12}>
            Settings
          </Text>
          <>
            {!isMobile ? (
              TABS.map((item, index) => (
                <Col
                  onPress={() => onChangeTabs(index)}
                  marginLeft={8}
                  paddingHorizontal={12}
                  paddingVertical={6}
                  borderRadius={5}
                  backgroundColor={index === currentTab ? COLOR.MAIN : COLOR.GREY}
                >
                  <Text
                    fontSize={12}
                    color={index === currentTab ? COLOR.WHITE : COLOR.BLACK}
                  >
                    {item.label}
                  </Text>
                </Col>
              ))
            ) : null}
          </>
        </Row>
        <Row>{button}</Row>
      </Row>
      {isMobile && (
        <Col m2>
          <Select
            bgWhite noBorder
            round0
            options={tabOptions}
            value={tabOptions.find((v) => v.value === currentTab)}
            onChange={(data) => onChangeTabs(data.value)}
            placeholder="Select settings"
          />
        </Col>
      )}
      <Col flex1 m2 mv1 p1 round1 bgWhite overflow={"scroll"}>
        <Grid xs="100%" md="100%" alignItems={"flex-start"} mb2>
          <Col m1>{content}</Col>
        </Grid>
      </Col>
    </CMSLayout>
  );
};

const SettingTabsWithContext = (props) => {
  return (
    <SettingsFormProvider>
      <SettingTabs {...props} />
    </SettingsFormProvider>
  )
}

SettingTabsWithContext.routeInfo = {
  title: "Settings",
  path: "/admin-settings",
};

export default SettingTabsWithContext;
