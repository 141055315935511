import Request from '../Request.utils'
export interface IListOrderApiRequestQuery {
  clientId?: string;
  dateType?: string;
  endDate?: string;
  env?: string;
  filter?: string;
  inactive?: '';
  includeInactive?: '';
  limit: number;
  offset: number;
  orderId?: string;
  orderNumber?: string;
  orderType?: string;
  sort?: string;
  stage?: string;
  stageStatus?: string;
  startDate?: string;
  status?: string;
  supported?: '';
}
export interface IUpdatePipelineShareDataApiRequestBody {
  data?: any;
  pipelineId: string;
}
export interface IUpdateOrderStatusApiRequestBody {
  Stage?: string;
  StageStatus?: string;
  Status?: string;
  id?: string;
  orderId?: string;
}
export interface IUpdateOrderStatusApiRequestQuery {
  id?: string;
  orderId?: string;
}
export interface IListPipelineApiRequestQuery {
  endDate?: string;
  limit: number;
  offset: number;
  orderId?: string;
  searchByOrderId?: string;
  sort?: string;
  startDate?: string;
}
export interface ITriggerDispatchRoyalMailApiRequestQuery {
  force?: boolean;
  id?: string;
  orderId?: string;
  serviceCode?: string;
}
export interface IRerunJobApiRequestBody {
  forceSkipCallback?: boolean;
  jobId: string;
  pipelineId: number;
  thisJobOnly?: boolean;
}
export interface IRunJobApiRequestBody {
  additionData?: any;
  error?: string;
  jobId: string;
  pipelineId: number;
  thisJobOnly?: boolean;
}
export interface IContinuePipelineApiRequestBody {
  pipelineId: number;
}
export interface IRequestGeneratePDFCallbackApiRequestBody {
  error?: string;
  pdfs?: any;
}
export interface IRequestGeneratePDFCallbackApiRequestQuery {
  orderId: string;
}
export interface IRequestGenerateCandleStickerCallbackApiRequestBody {
  error?: string;
  pdfs?: any;
}
export interface IRequestGenerateCandleStickerCallbackApiRequestQuery {
  orderId: string;
}
export interface IRunPipelineApiRequestBody {
  data: any;
  type: string;
}
export interface IShopifyWebhookApiRequestBody {}
export interface IShopifyWebhookApiRequestQuery {
  clientId?: string;
  clientName?: string;
  env?: string;
  storeId?: string;
}
export interface IEtsyWebhookApiRequestBody {}
export interface IEtsyWebhookApiRequestQuery {
  clientId?: string;
  clientName?: string;
  env?: string;
  storeId?: string;
}
export interface IFindOrderBySourceIdApiRequestQuery {
  id: string;
}


class BgAPI {
  listOrderApi = async (query: IListOrderApiRequestQuery) => {
    const res = await Request.call('/api/bg/listOrder', 'GET', undefined, query, undefined, );
    return res;
  }
  updatePipelineShareDataApi = async (body: IUpdatePipelineShareDataApiRequestBody) => {
    const res = await Request.call('/api/bg/pipeline/update-pipeline-share-data', 'POST', undefined, undefined, body, );
    return res;
  }
  updateOrderStatusApi = async (query: IUpdateOrderStatusApiRequestQuery, body: IUpdateOrderStatusApiRequestBody) => {
    const res = await Request.call('/api/bg/updateOrderStatus', 'POST', undefined, query, body, );
    return res;
  }
  listPipelineApi = async (query: IListPipelineApiRequestQuery) => {
    const res = await Request.call('/api/bg/pipeline/list', 'GET', undefined, query, undefined, );
    return res;
  }
  triggerDispatchRoyalMailApi = async (query: ITriggerDispatchRoyalMailApiRequestQuery) => {
    const res = await Request.call('/api/bg/trigger-dispatch-royalmail', 'POST', undefined, query, undefined, );
    return res;
  }
  rerunJobApi = async (body: IRerunJobApiRequestBody) => {
    const res = await Request.call('/api/bg/pipeline/rerun-job', 'POST', undefined, undefined, body, );
    return res;
  }
  runJobApi = async (body: IRunJobApiRequestBody) => {
    const res = await Request.call('/api/bg/pipeline/run-job', 'POST', undefined, undefined, body, );
    return res;
  }
  continuePipelineApi = async (body: IContinuePipelineApiRequestBody) => {
    const res = await Request.call('/api/bg/pipeline/continue', 'POST', undefined, undefined, body, );
    return res;
  }
  requestGeneratePDFCallbackApi = async (query: IRequestGeneratePDFCallbackApiRequestQuery, body: IRequestGeneratePDFCallbackApiRequestBody) => {
    const res = await Request.call('/api/bg/request-generate-pdf-callback', 'POST', undefined, query, body, );
    return res;
  }
  requestGenerateCandleStickerCallbackApi = async (query: IRequestGenerateCandleStickerCallbackApiRequestQuery, body: IRequestGenerateCandleStickerCallbackApiRequestBody) => {
    const res = await Request.call('/api/bg/request-generate-candle-sticker-callback', 'POST', undefined, query, body, );
    return res;
  }
  runPipelineApi = async (body: IRunPipelineApiRequestBody) => {
    const res = await Request.call('/api/bg/pipeline/run', 'POST', undefined, undefined, body, );
    return res;
  }
  shopifyWebhookApi = async (query: IShopifyWebhookApiRequestQuery, body: IShopifyWebhookApiRequestBody) => {
    const res = await Request.call('/api/bg/shopify-webhook', 'POST', undefined, query, body, );
    return res;
  }
  cleanTestOrderApi = async () => {
    const res = await Request.call('/api/bg/test/clean-test-order', 'POST', undefined, undefined, undefined, );
    return res;
  }
  etsyWebhookApi = async (query: IEtsyWebhookApiRequestQuery, body: IEtsyWebhookApiRequestBody) => {
    const res = await Request.call('/api/bg/etsy-webhook', 'POST', undefined, query, body, );
    return res;
  }
  findOrderBySourceIdApi = async (query: IFindOrderBySourceIdApiRequestQuery) => {
    const res = await Request.call('/api/bg/find-order-by-source-id', 'GET', undefined, query, undefined, );
    return res;
  }
}
export default new BgAPI()