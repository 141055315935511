import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Col, Row, showPopupMessage } from "components";
import { Button, Collapse, Form, Input, Space, Typography } from "antd";
import apiClient from "store/api-client";

const { TextArea } = Input;
const { Title } = Typography;
const { Panel } = Collapse;

interface FAQItem {
  question: string;
  answer: string;
}

interface IProps { }

const FAQSettings = (props: IProps, ref) => {
  const [form] = Form.useForm();
  const [activeKey, setActiveKey] = useState<string | string[]>([]);

  const getData = async () => {
    const res = await apiClient.Api.Settings.getFAQs();
    if (res.data?.data?.data) {
      form.setFieldsValue({
        faqs: JSON.parse(res.data.data.data),
      });
    }
  }

  useEffect(() => {
    getData();
  }, []);

  useImperativeHandle(ref, () => ({
    getData,
    updateFAQ: async () => {
      const faqs = form.getFieldsValue().faqs || [];

      // Additional validation to ensure no empty strings after trim
      const hasEmptyFields = faqs.some(faq =>
        !faq.question.trim() || !faq.answer.trim()
      );

      if (hasEmptyFields) {
        showPopupMessage({
          title: 'Validation Error',
          content: 'Please fill in all questions and answers',
          typeHighlight: 'error',
          contentHighlight: 'Error',
          buttonOkText: 'OK'
        });
        return;
      }
      const res = await apiClient.Api.Settings.updateFAQs({
        data: faqs,
      });
      if (res.data.success) {
        showPopupMessage({
          title: 'FAQ Updated',
          content: 'FAQ settings have been updated successfully',
          buttonOkText: 'OK',
          typeHighlight: 'success',
          contentHighlight: 'Success'
        });
        setActiveKey([]);
      }
      return res;
    }
  }));

  const styles = {
    panel: {
      marginBottom: 16,
      background: '#fff',
    },
    removeButton: {
      marginTop: 8,
    },
    addButton: {
      marginTop: 16,
    },
  };

  const getPanelTitle = (index: number, question: string) => {
    const defaultTitle = `Question ${index + 1}`;
    return question?.trim() || defaultTitle;
  };

  const handleAdd = () => {
    const newIndex = form.getFieldValue('faqs').length;
    form.setFieldsValue({
      faqs: [...form.getFieldValue('faqs'), { question: '', answer: '' }]
    });
    setActiveKey([String(newIndex)]);
  };

  return (
    <Col flex1 p1>
      <Form
        form={form}
        name="faq_settings"
        autoComplete="off"
        initialValues={{ faqs: [{ question: "", answer: "" }] }}
      >
        <Row justify="space-between" align="middle" style={{ marginBottom: 24 }}>
          <Title level={4}>FAQ Settings</Title>
        </Row>

        <Form.List name="faqs">
          {(fields, { add, remove }) => (
            <>
              <Collapse
                activeKey={activeKey}
                onChange={(keys) => setActiveKey(keys)}
              >
                {fields.map(({ key, name, ...restField }, index) => (
                  <Panel
                    key={String(index)}
                    header={getPanelTitle(
                      index,
                      form.getFieldValue(['faqs', index, 'question'])
                    )}
                    style={styles.panel}
                    extra={
                      fields.length > 1 && (
                        <Button
                          type="text"
                          danger
                          onClick={(e) => {
                            e.stopPropagation();
                            remove(name);
                            setActiveKey(
                              (Array.isArray(activeKey) ? activeKey : [activeKey])
                                .filter(key => Number(key) !== index)
                                .map(key => Number(key) > index ? String(Number(key) - 1) : key)
                            );
                          }}
                        >
                          Remove
                        </Button>
                      )
                    }
                  >
                    <Space
                      direction="vertical"
                      style={{ width: '100%' }}
                      size="large"
                    >
                      <Form.Item
                        {...restField}
                        name={[name, 'question']}
                        rules={[
                          { required: true, message: 'Question is required' },
                          {
                            validator: (_, value) => {
                              if (!value || !value.trim()) {
                                return Promise.reject('Question cannot be empty');
                              }
                              return Promise.resolve();
                            }
                          }
                        ]}
                        style={{ marginBottom: 0 }}
                        label="Question"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                      >
                        <Input
                          placeholder="Enter question"
                          onChange={(e) => {
                            form.setFieldsValue({
                              faqs: form.getFieldValue('faqs')
                            });
                          }}
                        />
                      </Form.Item>

                      <Form.Item
                        {...restField}
                        name={[name, 'answer']}
                        rules={[
                          { required: true, message: 'Answer is required' },
                          {
                            validator: (_, value) => {
                              if (!value || !value.trim()) {
                                return Promise.reject('Answer cannot be empty');
                              }
                              return Promise.resolve();
                            }
                          }
                        ]}
                        style={{ marginBottom: 8 }}
                        label="Answer"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                      >
                        <TextArea
                          placeholder="Enter answer"
                          rows={4}
                        />
                      </Form.Item>
                    </Space>
                  </Panel>
                ))}
              </Collapse>

              <Form.Item style={styles.addButton}>
                <Button
                  type="primary"
                  onClick={handleAdd}
                >
                  Add Question
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form>
    </Col>
  );
};

export default forwardRef(FAQSettings);
