import moment from 'moment';

class TimeHelper {
  runOnceInTimestamp = 0;
  lastCalled = 0;

  wait(miliseconds) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(undefined);
      }, miliseconds)
    });
  }

  formatDay(time) {
    return moment(time).format('DD/MM/YYYY');
  }

  formatDayTime(time) {
    return moment(time).format('DD/MM/YYYY HH:mm');
  }

  runOnceIn = miliseconds => func => {
    let newTimestamp = new Date().getTime();
    if (newTimestamp - this.runOnceInTimestamp > miliseconds) {
      this.runOnceInTimestamp = newTimestamp;
      if (typeof func === 'function') return func();
    }
  }

  throttle = (func, time = 2000) => {
    return () => {
      const now = Date.now();
      if (now - this.lastCalled > time) {
        func();
        this.lastCalled = now;
      }
    }
  }
}

export default new TimeHelper();
