import Request from '../Request.utils'
export interface IUpdateFAQsRequestBody {
  data?: any;
}


class SettingsAPI {
  getFAQs = async () => {
    const res = await Request.call('/api/settings/get-faqs', 'GET', undefined, undefined, undefined, );
    return res;
  }
  updateFAQs = async (body: IUpdateFAQsRequestBody) => {
    const res = await Request.call('/api/settings/update-faqs', 'POST', undefined, undefined, body, );
    return res;
  }
}
export default new SettingsAPI()